import React from "react";
import { GameConfig, GameSettings, UsersGuess } from "../types";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FilterOptionsState,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import Fuse from "fuse.js";

interface NewGameViewProps {
  conf: GameConfig;
  onGameSettingsUpdate?: (settings: GameSettings) => void;
}

export default function NewGameView({
  conf,
  onGameSettingsUpdate,
}: NewGameViewProps) {
  const [selectedPerson, setSelectedPerson] = React.useState<string | null>(
    null
  );
  const [guesses, setGuesses] = React.useState<string[]>([]);

  const [hintCounter, setHintCounter] = React.useState(1);
  const [guesesRemaining, setGuessesRemaining] = React.useState(5);
  const [userGuesses, setUserGuesses] = React.useState<UsersGuess[]>([]);

  const fuseSearch = React.useMemo(() => {
    const fuseOptions = {
      isCaseSensitive: false,
      includeScore: false,
      shouldSort: true,
      minMatchCharLength: 2,
      threshold: 0.45,
      //includeMatches: true,
      // findAllMatches: false,
      // location: 0,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ["name"],
    };
    return new Fuse(
      conf.names.map((v) => ({
        name: v,
      })),
      fuseOptions
    );
  }, [conf.names]);

  const onPersonSelected = React.useCallback(
    (_: any, newValue: string | null) => {
      setSelectedPerson(newValue);
    },
    [setSelectedPerson]
  );

  const onFilterOptions = React.useCallback(
    (_: string[], state: FilterOptionsState<string>) => {
      if (state.inputValue.length < 2) {
        return [];
      }

      const searchRes = fuseSearch.search(state.inputValue);
      const opts = searchRes.map((r) => r.item["name"] as string);

      return opts;
    },
    [fuseSearch]
  );

  const onGuessClick = React.useCallback(() => {
    if (selectedPerson !== null) {
      setGuesses([...guesses, selectedPerson]);
      const correct = conf.names[conf.index];
      if (correct === selectedPerson) {
        const usedGuesses: UsersGuess[] = [
          ...userGuesses,
          {
            hint: conf.facts[hintCounter - 1],
            guess: selectedPerson,
            result: true,
          },
        ];
        const newGameSettings: GameSettings = {
          conf: conf,
          game_result: {
            guesses: usedGuesses,
            game_result: true,
          },
        };
        if (onGameSettingsUpdate) {
          onGameSettingsUpdate(newGameSettings);
        }
      } else {
        const usedGuesses: UsersGuess[] = [
          ...userGuesses,
          {
            hint: conf.facts[hintCounter - 1],
            guess: selectedPerson,
            result: false,
          },
        ];
        setUserGuesses(usedGuesses);
        setHintCounter(hintCounter + 1);
        setGuessesRemaining(guesesRemaining - 1);
        if (guesesRemaining === 1) {
          const newGameSettings: GameSettings = {
            conf: conf,
            game_result: {
              guesses: usedGuesses,
              game_result: false,
            },
          };
          if (onGameSettingsUpdate) {
            onGameSettingsUpdate(newGameSettings);
          }
        }
      }
    }
    setSelectedPerson("");
  }, [
    conf,
    guesesRemaining,
    guesses,
    hintCounter,
    onGameSettingsUpdate,
    selectedPerson,
    userGuesses,
  ]);

  const guessButtonText = React.useMemo(() => {
    const textPart = selectedPerson ? "Guess" : "Skip";
    return `${textPart} (${guesesRemaining}/5)`;
  }, [guesesRemaining, selectedPerson]);

  return (
    <>
      <Grid item xs={12} padding={2}>
        <List sx={{ listStyleType: "disc" }}>
          {conf.facts.map((f, idx) => {
            if (idx < hintCounter) {
              return (
                <ListItem key={f} sx={{ display: "list-item" }}>
                  <Typography className="main-text-color">{f}</Typography>
                </ListItem>
              );
            }
            return <ListItem key={f} sx={{ display: "none" }}></ListItem>;
          })}
        </List>
      </Grid>
      <Grid item xs={8} padding={2}>
        <Autocomplete
          filterOptions={onFilterOptions}
          autoHighlight
          options={conf.names}
          onChange={onPersonSelected}
          value={selectedPerson}
          handleHomeEndKeys
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Enter person name"}
              variant="outlined"
              InputLabelProps={{
                className: "main-text-color-medium",
              }}
              sx={{
                "& .MuiInputBase-input": {
                  color: "rgb(221, 199, 161)",
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                },
                "& .MuiAutocomplete-inputRoot": {
                  paddingLeft: "20px !important",
                  borderRadius: "10px",
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          id={"guess_id_btn"}
          onClick={onGuessClick}
          disabled={guesesRemaining === 0}
          variant="outlined"
          className="main-text-color-medium"
          size="medium"
          sx={{
            height: 57,
            marginLeft: "-15px",
            borderRadius: "10px",
            borderColor: "grey",
            borderWidth: 1,
            ":hover": {
              border: "1px solid black",
            },
            input: {
              fontColor: "main-text-color-medium",
            },
            border: "1px solid grey",
          }}
        >
          {guessButtonText}
        </Button>
      </Grid>
      <Divider
        sx={{
          color: "black",
          width: "100%",
          backgroundColor: "grey",
        }}
      />
      <Grid item xs={12} paddingTop={4}>
        <Grid container spacing={2}>
          {userGuesses.map((g, i) => (
            <Grid item xs={3} key={g.hint}>
              <Typography className="main-text-color-small">
                ❌ {g.guess}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Box
          alignContent={"center"}
          component="img"
          sx={{
            width: "260px",
            height: "auto",
            objectFit: "contain",
            visibility: "hidden",
          }}
          alt={conf.names[conf.index]}
          src={conf.photo_link}
        />
      </Grid>
    </>
  );
}
