import React from "react";
import { GameSettings } from "../types";
import GameResultView from "./GameResultView";
import NewGameView from "./NewGameView";
import { Grid } from "@mui/material";

interface GameContainerProps {
  game_settings: GameSettings;
  onGameSettingsUpdate?: (settings: GameSettings) => void;
  daily_game: boolean;
  archive_conf_id?: string;
}

export default function GameContainer({
  game_settings: gameSettings,
  daily_game,
  onGameSettingsUpdate,
  archive_conf_id,
}: GameContainerProps) {
  const gameElement = React.useMemo(() => {
    if (gameSettings?.game_result) {
      return (
        <GameResultView
          conf={gameSettings.conf}
          game_result={gameSettings.game_result}
          daily_game={daily_game}
          archive_conf_id={archive_conf_id}
        />
      );
    } else if (gameSettings?.conf) {
      return (
        <NewGameView
          conf={gameSettings.conf}
          onGameSettingsUpdate={onGameSettingsUpdate}
        />
      );
    }

    return <></>;
  }, [
    archive_conf_id,
    daily_game,
    gameSettings.conf,
    gameSettings.game_result,
    onGameSettingsUpdate,
  ]);

  return (
    // <Container maxWidth="sm">
    //   <Grid
    //     container
    //     direction="row"
    //     justifyContent="center"
    //     alignItems="center"
    //     paddingTop={2}
    //   >
    //     {gameSettings && gameElement}
    //   </Grid>
    // </Container>
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      paddingTop={2}
      maxWidth={"sm"}
    >
      {gameSettings && gameElement}
    </Grid>
  );
}
