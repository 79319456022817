import { Box, Modal } from "@mui/material";
import React from "react";

const style = {
  bgcolor: "rgb(45, 55, 65)",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -55%)",
  width: 265,
  height: 550,
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AppModal({
  children,
  isOpen,
  onModalClose,
}: AppModalProps) {
  const handleModalClick = React.useCallback(() => {
    onModalClose(!isOpen);
  }, [isOpen, onModalClose]);

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="main-text-color-small">
        {children}
      </Box>
    </Modal>
  );
}

interface AppModalProps {
  onModalClose: (state: boolean) => void;
  isOpen: boolean;

  children: React.ReactNode;
}
