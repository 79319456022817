/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Grid, Typography } from "@mui/material";

const HISTORY_KEY = "HISTORY_KEY";

const DEFAULT_HISTORY: UserHistory = {
  configs_played: [],
  guesses: [0, 0, 0, 0, 0, 0],
  games_played: 0,
  wins: 0,
};

export function getHistory(): UserHistory {
  const storageVal = localStorage.getItem(HISTORY_KEY);
  if (!storageVal) {
    return DEFAULT_HISTORY;
  }
  const history = JSON.parse(storageVal) as UserHistory;
  return history;
}

export function updateHistory(
  playedConfigId: string,
  guessesUsed: number,
  gameWin: boolean
): void {
  const storageVal = localStorage.getItem(HISTORY_KEY);
  const currentHistory = storageVal
    ? (JSON.parse(storageVal) as UserHistory)
    : DEFAULT_HISTORY;

  const alreadyPlayed = currentHistory.configs_played.includes(playedConfigId);
  if (alreadyPlayed) {
    return;
  }

  const confsPlayed = currentHistory.configs_played;
  const guesses = currentHistory.guesses;

  confsPlayed.push(playedConfigId);

  if (gameWin) {
    const guessIdx = guessesUsed - 1;
    guesses[guessIdx] = guesses[guessIdx] + 1;
  } else {
    guesses[5] = guesses[5] + 1;
  }

  const newHistory: UserHistory = {
    guesses: guesses,
    configs_played: confsPlayed,
    games_played: currentHistory.games_played + 1,
    wins: currentHistory.wins + (gameWin ? 1 : 0),
  };
  localStorage.setItem(HISTORY_KEY, JSON.stringify(newHistory));
}

interface UserHistory {
  guesses: number[];
  configs_played: string[];
  games_played: number;
  wins: number;
}

function GameStatsBarChart() {
  const ref = useRef();
  const gameData = getHistory();
  const containerWidth = 300;
  const containerHeigth = 450;

  const [zIndex, setZIndex] = React.useState(1);
  React.useMemo(() => {
    const max = Math.max(...gameData.guesses);
    const prepared: Array<{ key: string; value: number }> = [];
    for (let i = 0; i < gameData.guesses.length - 1; i++) {
      prepared.push({
        key: `${i + 1}`,
        value: gameData.guesses[i],
      });
    }

    prepared.push({
      key: "Loss",
      value: gameData.guesses[gameData.guesses.length - 1],
    });

    const yAxisValues: number[] = [];
    for (let i = 1; i <= max; i++) {
      if (max < 10) {
        yAxisValues.push(i);
      } else if (max < 50) {
        if (i % 5 === 0) {
          yAxisValues.push(i);
        }
      } else if (max < 100) {
        if (i % 10 === 0) {
          yAxisValues.push(i);
        }
      }
    }

    // set the dimensions and margins of the graph
    const margin = { top: 30, right: 30, bottom: 250, left: 25 },
      width = containerWidth - margin.left - margin.right,
      height = containerHeigth - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select(ref.current as any)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(prepared.map((d) => d.key))
      .padding(0.2);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .attr("class", "axis-style")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end");

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([0, max + 1])
      .range([height, 0]);
    svg
      .append("g")
      .attr("class", "axis-style")
      .call(d3.axisLeft(y).tickValues(yAxisValues));

    // Bars
    svg
      .selectAll("mybar")
      .data(prepared)
      .join("rect")
      .attr("x", (d) => x(d.key) ?? "")
      .attr("y", (d) => y(d.value))
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - y(d.value))
      .style("fill", (d, i) => {
        return "rgb(82, 133, 115)";
      });

    setTimeout(() => {
      setZIndex(999);
    }, 10);
  }, [gameData, ref]);

  return (
    <svg
      width={containerWidth}
      height={containerHeigth}
      id="barchart"
      ref={ref as any}
      viewBox="0 0 305 300"
      style={{ zIndex: zIndex, paddingRight: 10 }}
    />
  );
}

export default function History() {
  const gameData = getHistory();
  return (
    <Grid container className="main-text-color" alignContent={"center"}>
      {gameData.games_played !== 0 && (
        <>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "1.2em", fontWeight: 600 }}>
              Games played: {gameData.games_played}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "1.2em", fontWeight: 600 }}>
              Wins: {gameData.wins}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "1.2em", fontWeight: 600 }}>
              Win rate:{" "}
              {((gameData.wins / gameData.games_played) * 100).toFixed(2)}%
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ zIndex: 10 }}>
            <GameStatsBarChart />
          </Grid>
        </>
      )}
      {gameData.games_played == 0 && (
        <>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "1.2em", fontWeight: 600 }}>
              No games played yet.
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}
