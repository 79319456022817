import React from "react";

import { useLoaderData } from "react-router-dom";
import { Grid, IconButton, Typography } from "@mui/material";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { GameConfig, GameSettings } from "../../components/types";
import MainLayout from "../../components/MainLayout";
import GameContainer from "../../components/GameContainer";

export async function archiveLoader({ params }: { params: any }) {
  const maxId = 65;
  const minId = 1;
  let reqId = Math.max(minId, maxId);
  if (params.id) {
    const parsedParam = parseInt(params.id, 10);
    if (parsedParam >= minId && parsedParam <= maxId) {
      reqId = parsedParam;
    }
  }

  const archiveConf = await loadArchive(reqId);
  return {
    conf: archiveConf,
    min_id: minId,
    max_id: maxId,
  };
}

async function loadArchive(id: number): Promise<GameConfig> {
  const archResp = await fetch(
    `${process.env.REACT_APP_API_URL}/game/archive`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
      }),
    }
  );

  const archiveConf = (await archResp.json()) as GameConfig;

  return archiveConf;
}

export default function Archive() {
  const data = useLoaderData() as {
    conf: GameConfig;
    min_id: number;
    max_id: number;
  };
  const [disabledLeft, setDisabledLeft] = React.useState(() => {
    if (parseInt(data?.conf.config_id ?? "1", 10) === data.min_id) {
      return true;
    }

    return false;
  });

  const [disabledRight, setDisabledRight] = React.useState(() => {
    if (parseInt(data?.conf.config_id ?? "1", 10) === data.max_id) {
      return true;
    }

    return false;
  });

  const [gameSettings, setGameSettings] = React.useState<GameSettings | null>({
    conf: data.conf,
    game_result: null,
  });

  const onLeftClicked = React.useCallback(async () => {
    const reqId = Math.max(
      data.min_id,
      parseInt(gameSettings?.conf.config_id ?? "1", 10) - 1
    );
    const conf = await loadArchive(reqId);
    setGameSettings({
      conf,
      game_result: null,
    });

    if (data.min_id === reqId) {
      setDisabledLeft(true);
    }

    if (data.max_id !== reqId) {
      setDisabledRight(false);
    }
  }, [data.max_id, data.min_id, gameSettings?.conf.config_id]);

  const onRightClicked = React.useCallback(async () => {
    const reqId = Math.min(
      data.max_id,
      parseInt(gameSettings?.conf.config_id ?? "1", 10) + 1
    );
    const conf = await loadArchive(reqId);
    setGameSettings({
      conf,
      game_result: null,
    });

    if (data.min_id !== reqId) {
      setDisabledLeft(false);
    }

    if (data.max_id === reqId) {
      setDisabledRight(true);
    }
  }, [data.max_id, data.min_id, gameSettings?.conf.config_id]);

  return (
    <MainLayout>
      {gameSettings && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          paddingTop={2}
          maxWidth={"sm"}
          id={"archive_page"}
        >
          <Grid item xs={12} className="main-text-color" paddingLeft={0}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              paddingTop={2}
              maxWidth={"sm"}
            >
              <Grid item xs={4}>
                <IconButton
                  disabled={disabledLeft}
                  onClick={onLeftClicked}
                  className="main-text-color"
                  size="small"
                  sx={{
                    height: 57,
                    borderRadius: "10px",
                    borderColor: "grey",
                    borderWidth: 1,
                    ":hover": {
                      border: "1px solid black",
                    },
                    input: {
                      fontColor: "main-text-color-medium",
                    },
                    border: "1px solid grey",
                    opacity: disabledLeft ? 0.5 : 1,
                  }}
                >
                  <KeyboardArrowLeftIcon
                    htmlColor="rgb(221, 199, 161)"
                    fontSize="medium"
                  />
                  <Typography
                    className="main-text-color"
                    sx={{ fontSize: "0.8em", fontWeight: 600 }}
                    paddingRight={1}
                  >
                    prev.
                  </Typography>
                </IconButton>
              </Grid>
              {/* <Grid item xs={3} paddingRight={2}>
                <Typography sx={{ fontSize: "1em", fontWeight: 600 }}>
                  {selectedDate}
                </Typography>
              </Grid> */}
              <Grid item xs={4} paddingLeft={6}>
                <Typography sx={{ fontSize: "1em", fontWeight: 600 }}>
                  {`#${gameSettings.conf.config_id}`}
                </Typography>
              </Grid>
              <Grid item xs={4} paddingLeft={5}>
                <IconButton
                  disabled={disabledRight}
                  onClick={onRightClicked}
                  className="main-text-color"
                  size="small"
                  sx={{
                    height: 57,
                    borderRadius: "10px",
                    borderColor: "grey",
                    borderWidth: 1,
                    ":hover": {
                      border: "1px solid black",
                    },
                    input: {
                      fontColor: "main-text-color-medium",
                    },
                    border: "1px solid grey",
                    opacity: disabledRight ? 0.5 : 1,
                  }}
                >
                  <Typography
                    className="main-text-color"
                    sx={{ fontSize: "0.8em", fontWeight: 600 }}
                    paddingLeft={1}
                  >
                    next
                  </Typography>
                  <KeyboardArrowRightIcon
                    htmlColor="rgb(221, 199, 161)"
                    fontSize="medium"
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <GameContainer
              game_settings={gameSettings}
              daily_game={false}
              onGameSettingsUpdate={setGameSettings}
              archive_conf_id={gameSettings.conf.config_id}
            />
          </Grid>
        </Grid>
      )}
    </MainLayout>
  );
}
