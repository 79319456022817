import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Privacy from "./pages/privacy";
import Archive, { archiveLoader } from "./pages/archive";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <App />,
  },
  {
    path: "/index.html",
    element: <App />,
    errorElement: <App />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
    errorElement: <App />,
  },
  {
    path: "/archive/:id",
    element: <Archive />,
    loader: archiveLoader,
    errorElement: <App />,
  },
  {
    path: "/archive",
    element: <Archive />,
    loader: archiveLoader,
    errorElement: <App />,
  },
]);

export default router;
