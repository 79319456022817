import {
  Grid,
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  Button,
  Divider,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import AppModal from "../AppModal";
import { updateHistory } from "../History";
import { GameConfig, GameResult, UsersGuess } from "../types";

import IosShareIcon from "@mui/icons-material/IosShare";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import History from "../History";

interface GameResultViewProps {
  conf: GameConfig;
  game_result: GameResult;
  daily_game: boolean;
  archive_conf_id?: string;
}

export default function GameResultView({
  conf,
  game_result,
  daily_game,
  archive_conf_id,
}: GameResultViewProps) {
  const [copyModalOpen, setCopyModalOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setCopyModalOpen(true);
  }, [setCopyModalOpen]);
  const handleClose = React.useCallback(() => {
    setCopyModalOpen(false);
  }, [setCopyModalOpen]);

  React.useEffect(() => {
    if (daily_game) {
      updateHistory(
        conf.config_id,
        game_result.guesses.length,
        game_result.game_result
      );
    }
  }, [
    conf.config_id,
    daily_game,
    game_result.game_result,
    game_result.guesses.length,
  ]);

  const [allHintsIncluded, setAllHintsIncluded] = React.useState(false);
  const [viewStats, setViewStats] = React.useState(false);

  const [guessesToShow, setGuessesToShow] = React.useState<UsersGuess[]>(
    game_result.guesses
  );

  const [timeToNewGame, setTimeToNewGame] = React.useState("");

  React.useEffect(() => {
    setInterval(() => {
      const utc = DateTime.utc();
      const utcNextDay = DateTime.utc().plus({ days: 1 }).startOf("day");
      const diffComplex = utcNextDay.diff(utc, ["hours", "minutes", "seconds"]);

      const hours =
        Math.floor(diffComplex.hours) === 0
          ? "00"
          : Math.floor(diffComplex.hours);
      const hoursStr = hours === "00" ? "00" : hours > 9 ? hours : `0${hours}`;
      const mins =
        Math.floor(diffComplex.minutes) === 0
          ? "00"
          : Math.floor(diffComplex.minutes);
      const minsStr = mins === "00" ? "00" : mins > 9 ? mins : `0${mins}`;
      const secs =
        Math.floor(diffComplex.seconds) === 0
          ? "00"
          : Math.floor(diffComplex.seconds);
      const secsStr = secs === "00" ? "00" : secs > 9 ? secs : `0${secs}`;

      const timeLeftStr = [hoursStr, minsStr, secsStr].join(":");
      setTimeToNewGame(timeLeftStr);
    }, 1000);
  }, [setTimeToNewGame]);

  const onShowHintsClicked = React.useCallback(() => {
    if (allHintsIncluded) {
      setGuessesToShow(game_result.guesses);
    } else {
      const guessesLen = game_result.guesses.length;
      const allGuesses = [...game_result.guesses];
      for (let i = guessesLen; i < conf.facts.length; i++) {
        allGuesses.push({
          guess: "",
          hint: conf.facts[i],
          result: false,
        });
      }
      setGuessesToShow(allGuesses);
    }

    setAllHintsIncluded(!allHintsIncluded);
  }, [allHintsIncluded, conf.facts, game_result.guesses]);

  const onShareButtonClick = React.useCallback(async () => {
    const gameNumber = `GuessMyName #${conf.config_id}`;
    const hintStatus = `${game_result.guesses
      .map((r) => (r.result ? "✅" : "❌"))
      .join(" ")}`;
    const link = archive_conf_id
      ? `https://guessmyna.me/archive/${archive_conf_id}`
      : `https://guessmyna.me`;
    const result = `${gameNumber}\n${hintStatus}\n${link}`;
    await navigator.clipboard.writeText(result);

    handleOpen();
    setTimeout(() => {
      handleClose();
    }, 2100);
  }, [
    archive_conf_id,
    conf.config_id,
    game_result.guesses,
    handleClose,
    handleOpen,
  ]);

  const onPlayMoreClick = React.useCallback(() => {
    window.location.href = "https://guessmyna.me/archive";
  }, []);

  return (
    <Grid item xs={12} padding={2}>
      <Modal
        open={copyModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "17%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            opacity: 0.8,
            width: 300,
            bgcolor: "rgb(50, 60, 70)",
            border: 0,
            boxShadow: "none",
          }}
        >
          <Typography id="modal-modal-description" textAlign={"center"}>
            result copied to the clipboard
          </Typography>
        </Box>
      </Modal>
      <Grid container spacing={2} direction="row" justifyContent="center">
        <Grid item xs={9}>
          <List id={"game_result_list"} sx={{ listStyleType: "disc" }}>
            {guessesToShow.map((guess, idx) => {
              if (guess.result) {
                return (
                  <ListItem key={guess.hint}>
                    <Typography className="main-text-color">
                      ✅ {guess.hint}
                    </Typography>
                  </ListItem>
                );
              }
              return (
                <ListItem key={guess.hint}>
                  <Typography className="main-text-color">
                    ❌ {guess.hint}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        {game_result.guesses.length < 5 && (
          <Grid item xs={3}>
            <Button
              className="main-text-color-medium"
              onClick={onShowHintsClicked}
              sx={{
                marginTop: 2,
                color: "#fff",
                opacity: 0.8,
                height: 45,
                marginLeft: "-15px",
                borderRadius: "10px",
                borderColor: "grey",
                borderWidth: 1,
                ":hover": {
                  border: "1px solid black",
                },
                input: {
                  fontColor: "main-text-color-small",
                },
                border: "1px solid grey",
              }}
            >
              Hints
              <VisibilityIcon sx={{ paddingLeft: 2 }}></VisibilityIcon>
            </Button>
          </Grid>
        )}
      </Grid>
      <Divider
        sx={{
          color: "black",
          width: "100%",
          backgroundColor: "grey",
        }}
      />
      <Grid item xs={12} paddingTop={4}>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography className="main-text-color">
              {conf.names[conf.index]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} direction={"row"} alignItems="center">
              {daily_game && (
                <>
                  <Grid item xs={6}>
                    <Button
                      id={"view_game_stats"}
                      onClick={() => setViewStats(true)}
                      variant="outlined"
                      size="medium"
                      sx={{
                        backgroundColor: "rgb(125, 174, 163)",
                        color: "#fff",
                        opacity: 0.8,
                        height: 57,
                        marginLeft: "-15px",
                        borderRadius: "10px",
                        borderColor: "grey",
                        borderWidth: 1,
                        ":hover": {
                          border: "1px solid black",
                        },
                        input: {
                          fontColor: "main-text-color-small",
                        },
                        border: "1px solid grey",
                      }}
                    >
                      VIEW STATS
                      <LeaderboardIcon sx={{ paddingLeft: 1 }} />
                    </Button>

                    <AppModal
                      isOpen={viewStats}
                      onModalClose={() => setViewStats(false)}
                    >
                      <Grid container>
                        <Grid item xs={11}>
                          <History />
                        </Grid>
                        <Grid item xs={1}>
                          <Button
                            sx={{
                              width: "25px",
                              minWidth: "25px",
                              maxWidth: "25px",
                            }}
                            onClick={() => setViewStats(false)}
                          >
                            <CloseIcon
                              htmlColor="rgb(221, 199, 161)"
                              fontSize="medium"
                            ></CloseIcon>
                          </Button>
                        </Grid>
                      </Grid>
                    </AppModal>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      id={"share_id_btn"}
                      onClick={onShareButtonClick}
                      variant="outlined"
                      size="medium"
                      sx={{
                        backgroundColor: "rgb(125, 174, 163)",
                        color: "#fff",
                        opacity: 0.8,
                        height: 57,
                        marginLeft: "-15px",
                        borderRadius: "10px",
                        borderColor: "grey",
                        borderWidth: 1,
                        ":hover": {
                          border: "1px solid black",
                        },
                        input: {
                          fontColor: "main-text-color-small",
                        },
                        border: "1px solid grey",
                      }}
                    >
                      SHARE RESULT
                      <IosShareIcon sx={{ paddingLeft: 1 }} />
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            {archive_conf_id && (
              <Grid item xs={12} paddingTop={2}>
                <Button
                  id={"share_id_btn"}
                  onClick={onShareButtonClick}
                  variant="outlined"
                  size="medium"
                  sx={{
                    backgroundColor: "rgb(125, 174, 163)",
                    color: "#fff",
                    opacity: 0.8,
                    height: 57,
                    marginLeft: "-15px",
                    borderRadius: "10px",
                    borderColor: "grey",
                    borderWidth: 1,
                    ":hover": {
                      border: "1px solid black",
                    },
                    input: {
                      fontColor: "main-text-color-small",
                    },
                    border: "1px solid grey",
                  }}
                >
                  SHARE RESULT
                  <IosShareIcon sx={{ paddingLeft: 1 }} />
                </Button>
              </Grid>
            )}
          </Grid>
          {daily_game && timeToNewGame && (
            <Grid item xs={12}>
              <Typography className="main-text-color">
                New game starts in {timeToNewGame}
              </Typography>
            </Grid>
          )}
          {daily_game && (
            <Grid item xs={2}>
              <Button
                id={"play_more_btn"}
                onClick={onPlayMoreClick}
                variant="outlined"
                size="medium"
                sx={{
                  backgroundColor: "rgb(125, 174, 163)",
                  color: "#fff",
                  opacity: 0.8,
                  height: 57,
                  marginLeft: "-15px",
                  borderRadius: "10px",
                  borderColor: "grey",
                  borderWidth: 1,
                  ":hover": {
                    border: "1px solid black",
                  },
                  input: {
                    fontColor: "main-text-color-small",
                  },
                  border: "1px solid grey",
                }}
              >
                Play more !
                <SportsEsportsIcon sx={{ paddingLeft: 1 }} />
              </Button>
            </Grid>
          )}
          <Grid item md={12}>
            <Box
              alignContent={"center"}
              component="img"
              sx={{
                width: "300px",
                height: "auto",
                objectFit: "contain",
              }}
              alt={conf.names[conf.index]}
              src={conf.photo_link}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
