"use client";

import {
  Grid,
  AppBar,
  Toolbar,
  Link,
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";

import React from "react";
import AppModal from "../AppModal";

import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CloseIcon from "@mui/icons-material/Close";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";

import History from "../History";

export default function MainLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const [statsModalOpen, setStatsModalOpen] = React.useState(false);

  const handleInfoModalClose = React.useCallback(() => {
    setInfoModalOpen(false);
  }, []);

  const handleStatsModalClose = React.useCallback(() => {
    setStatsModalOpen(false);
  }, []);

  React.useEffect(() => {
    const ONBOARDED = "ONBOARDED";
    const onboarded = localStorage.getItem(ONBOARDED);
    if (!onboarded) {
      localStorage.setItem(ONBOARDED, "true");
      setInfoModalOpen(true);
    }
  }, [setInfoModalOpen]);

  const onPrivacyPolicyClick = React.useCallback(() => {
    window.location.href = `${window.origin}/privacy`;
  }, []);

  return (
    <Grid
      direction="column"
      alignItems="center"
      justifyContent="center"
      container
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "rgb(45, 55, 65)",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Link href="https://guessmyna.me">
            <Box
              alignContent={"center"}
              component="img"
              sx={{
                width: "35px",
                height: "auto",
                objectFit: "contain",
              }}
              alt={""}
              src={"/gmn_image.webp"}
            ></Box>
          </Link>

          <div>
            <IconButton
              color="inherit"
              onClick={() =>
                (window.location.href = "https://guessmyna.me/archive")
              }
              id="archive_btn_menu"
            >
              <SportsEsportsIcon htmlColor="rgb(221, 199, 161)" />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={() => setStatsModalOpen(true)}
              id="view_game_stats_menu"
            >
              <LeaderboardIcon htmlColor="rgb(221, 199, 161)" />
            </IconButton>
            <IconButton color="inherit" onClick={() => setInfoModalOpen(true)}>
              <QuestionMarkIcon htmlColor="rgb(221, 199, 161)" />
            </IconButton>
            <IconButton
              color="inherit"
              onClick={onPrivacyPolicyClick}
              sx={{ textTransform: "none" }}
            >
              <PrivacyTipIcon
                htmlColor="rgb(221, 199, 161)"
                sx={{ paddingRight: 1 }}
              ></PrivacyTipIcon>
              <Typography className="main-text-color-small">
                Privacy policy
              </Typography>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <AppModal isOpen={infoModalOpen} onModalClose={handleInfoModalClose}>
        <Grid container>
          <Grid item xs={11}>
            <Typography
              variant="h5"
              sx={{ fontSize: "1.2em", fontWeight: 600 }}
            >
              Objective
            </Typography>
            <Typography padding={1} sx={{ fontSize: "1.1em" }}>
              The goal of the game is to correctly guess the hidden famous
              person based on a series of provided hints. Each day you will have
              new puzzle.
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontSize: "1.2em", fontWeight: 600 }}
            >
              How to play
            </Typography>
            <Typography padding={1} sx={{ fontSize: "1.1em" }}>
              Hints are given one at a time. Once you feel confident, click the
              &ldquo;Guess&ldquo; button to validate your assumption.
              <br />
              Continue to the next hint and use the additional information to
              refine your guess.
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontSize: "1.2em", fontWeight: 600 }}
            >
              Celebrate Your Success
            </Typography>
            <Typography padding={1} sx={{ fontSize: "1.1em" }}>
              Once you correctly guess a famous person, celebrate your success!
              <br />
              Consider sharing your achievement on social media or with friends
              to challenge them to play as well.
            </Typography>
            <Typography padding={1} sx={{ fontSize: "0.9em" }}>
              Contact us via
              <a
                className="underline"
                href="mailto:gameguessmyname@gmail.com"
                style={{
                  textDecoration: "underline",
                  paddingLeft: "0.25em",
                  color: "inherit",
                }}
              >
                email
              </a>
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={{ width: "25px", minWidth: "25px", maxWidth: "25px" }}
              onClick={handleInfoModalClose}
            >
              <CloseIcon
                htmlColor="rgb(221, 199, 161)"
                fontSize="medium"
              ></CloseIcon>
            </Button>
          </Grid>
        </Grid>
      </AppModal>
      <AppModal isOpen={statsModalOpen} onModalClose={handleStatsModalClose}>
        <Grid container>
          <Grid item xs={11}>
            <History />
          </Grid>
          <Grid item xs={1}>
            <Button
              sx={{ width: "25px", minWidth: "25px", maxWidth: "25px" }}
              onClick={handleStatsModalClose}
            >
              <CloseIcon
                htmlColor="rgb(221, 199, 161)"
                fontSize="medium"
              ></CloseIcon>
            </Button>
          </Grid>
        </Grid>
      </AppModal>

      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}
