import { Grid, Container, Typography } from "@mui/material";
import MainLayout from "../../components/MainLayout";

export default function Privacy() {
  return (
    <MainLayout>
      <Grid
        direction="column"
        alignItems="center"
        justifyContent="center"
        container
      >
        <Grid item xs={12} className="main-text-color-medium">
          <Container maxWidth="sm">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              paddingTop={2}
            >
              <Typography variant="h5">Introduction</Typography>
              <Typography padding={2}>
                guessmyna.me is committed to protecting the privacy of our
                players. We understand the importance of maintaining the
                confidentiality and security of your personal information, and
                we take all necessary steps to ensure that it remains protected.
                This Privacy Policy outlines how we collect, use, and protect
                your personal information when you visit our website. By using
                our website, you consent to our Privacy Policy and the terms of
                service outlined herein.
              </Typography>
              <Typography variant="h5">Use of Cookies</Typography>
              <Typography padding={2}>
                When you visit our website, we and our partners may collect data
                and use cookies for ad personalization, monitoring website
                performance, and to enhance your browsing experience. Cookies
                are small text files that are placed on your computer or device
                by our website. They enable us to remember your preferences,
                customize your browsing experience, and improve our
                website&apos;s functionality. We assure you that your data is
                always protected and used only for the purpose of providing you
                with a better browsing experience.
              </Typography>
              <Typography variant="h5">Advertising</Typography>
              <Typography padding={2}>
                To keep our website running and to provide quality challenges
                for our players, we rely on advertising as a source of revenue.
              </Typography>
              <Typography variant="h5">
                Links to Third-Party Websites
              </Typography>
              <Typography padding={2}>
                Our website may contain links to third-party websites. We are
                not responsible for the privacy policies or practices of these
                third-party websites. We recommend that you review the privacy
                policy of each website you visit.
              </Typography>
              <Typography variant="h5">Changes to Privacy Policy</Typography>
              <Typography padding={2}>
                We reserve the right to change this Privacy Policy at any time
                without prior notice. Any changes will be posted on this page,
                and your continued use of our website after the changes have
                been posted will be deemed as your acceptance of the new Privacy
                Policy.
              </Typography>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
