import React from "react";
import MainLayout from "./components/MainLayout";
import { GameConfig, GameSettings } from "./components/types";
import GameContainer from "./components/GameContainer";
import { Button, Divider, Grid, Typography } from "@mui/material";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
const GAME_SETTING_KEY = "GAME_SETTING_KEY";

const saveLocalStorageGameSettings = (gameSettings: GameSettings) => {
  localStorage.setItem(GAME_SETTING_KEY, JSON.stringify(gameSettings));
};

function App() {
  const [gameSettings, setGameSettings] = React.useState<GameSettings | null>(
    null
  );

  const [suspendedMode, _] = React.useState<boolean>(
    Date.now() > new Date(2024, 2, 25).getTime()
  );

  const loadConfig = React.useCallback(async () => {
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/game/conf`, {
      method: "POST",
    });
    const conf = (await resp.json()) as GameConfig;

    const storage = localStorage.getItem(GAME_SETTING_KEY);
    const savedSettings = storage
      ? (JSON.parse(storage) as GameSettings)
      : null;
    if (savedSettings && savedSettings.conf?.config_id === conf.config_id) {
      setGameSettings(savedSettings);
    } else {
      const newGameSettings: GameSettings = {
        conf: conf,
        game_result: null,
      };
      setGameSettings(newGameSettings);
      localStorage.setItem(GAME_SETTING_KEY, JSON.stringify(newGameSettings));
    }
  }, [setGameSettings]);

  React.useEffect(() => {
    if (!suspendedMode) {
      loadConfig();
    }
  }, [loadConfig, suspendedMode]);

  const onGameSettingsUpdate = React.useCallback((settings: GameSettings) => {
    saveLocalStorageGameSettings(settings);
    setGameSettings(settings);
  }, []);

  return (
    <MainLayout>
      {suspendedMode && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingTop={2}
          maxWidth={"sm"}
          className="main-text-color"
        >
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "1em", fontWeight: 600 }}>
              Hello, Guest! It was an amazing journey with this project over the
              last months but it is time to move on.
            </Typography>
            <Typography
              sx={{ fontSize: "1em", fontWeight: 600 }}
              paddingTop={1}
            >
              From now on that project is no longer maintained but you can
              always play an Archive.
            </Typography>
            <Divider
              sx={{
                color: "black",
                width: "100%",
                backgroundColor: "grey",
              }}
            />
          </Grid>
          <Grid item xs={2} paddingTop={2}>
            <Button
              id={"play_more_btn"}
              onClick={() =>
                (window.location.href = "https://guessmyna.me/archive")
              }
              variant="outlined"
              size="medium"
              sx={{
                backgroundColor: "rgb(125, 174, 163)",
                color: "#fff",
                opacity: 0.8,
                height: 57,
                marginLeft: "-15px",
                borderRadius: "10px",
                borderColor: "grey",
                borderWidth: 1,
                ":hover": {
                  border: "1px solid black",
                },
                input: {
                  fontColor: "main-text-color-small",
                },
                border: "1px solid grey",
              }}
            >
              Play Archive !
              <SportsEsportsIcon sx={{ paddingLeft: 1 }} />
            </Button>
          </Grid>
        </Grid>
      )}
      {!suspendedMode && gameSettings && (
        <GameContainer
          game_settings={gameSettings}
          daily_game={true}
          onGameSettingsUpdate={onGameSettingsUpdate}
        />
      )}
    </MainLayout>
  );
}

export default App;
